import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type MngRefreshSessionVariables = Types.Exact<{
  refreshToken: Types.Scalars['String']['input'];
}>;


export type MngRefreshSession = { __typename?: 'Mutation', mngRefreshSession: { __typename?: 'CognitoAuthModel', refreshToken?: string | null, accessToken: string, expiresIn: number } };


export const MngRefreshSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MngRefreshSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mngRefreshSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"refreshToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}},{"kind":"Field","name":{"kind":"Name","value":"accessToken"}},{"kind":"Field","name":{"kind":"Name","value":"expiresIn"}}]}}]}}]} as unknown as DocumentNode;
export type MngRefreshSessionMutationFn = Apollo.MutationFunction<MngRefreshSession, MngRefreshSessionVariables>;

/**
 * __useMngRefreshSession__
 *
 * To run a mutation, you first call `useMngRefreshSession` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMngRefreshSession` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mngRefreshSession, { data, loading, error }] = useMngRefreshSession({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useMngRefreshSession(baseOptions?: ApolloReactHooks.MutationHookOptions<MngRefreshSession, MngRefreshSessionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MngRefreshSession, MngRefreshSessionVariables>(MngRefreshSessionDocument, options);
      }
export type MngRefreshSessionHookResult = ReturnType<typeof useMngRefreshSession>;
export type MngRefreshSessionMutationResult = Apollo.MutationResult<MngRefreshSession>;
export type MngRefreshSessionMutationOptions = Apollo.BaseMutationOptions<MngRefreshSession, MngRefreshSessionVariables>;