import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  svgNavLogoIconTop: {
    margin: '23px auto 20px',
  },

  svgNavLogoIconBottom: {
    margin: '26px auto',
  },
}));
