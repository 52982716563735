import { changeLibLanguage as changeCoreLanguage } from '@lib/core';
import { changeLibLanguage as changeBllLanguage } from '@lib/features-bll';
import { changeLibLanguage as changeUiLanguage, PortalThemeProvider as UIPortalThemeProvider } from '@lib/features-ui';
import { changeLibLanguage } from '@lib/react-components';
import { ReactNode } from 'react';

import { changeLanguage } from 'common/i18n';

interface IPortalThemeProvider {
  children: ReactNode;
}

export const PortalThemeProvider = ({ children }: IPortalThemeProvider): JSX.Element => (
  <UIPortalThemeProvider
    changeLanguage={(language): void => {
      changeLibLanguage(language);
      changeLanguage(language);
      changeCoreLanguage(language);
      changeBllLanguage(language);
      changeUiLanguage(language);
    }}
  >
    {children}
  </UIPortalThemeProvider>
);
