import { Box } from '@mui/material';

import { useStyles } from './styles';

import { UserInfoDropdown } from 'common/components/UserInfoDropdown';
import { PortalSelect } from 'features/Portals';

export const Header = (): JSX.Element => {
  const { classes: localClasses } = useStyles();

  return (
    <Box className={localClasses.header}>
      <PortalSelect />
      <UserInfoDropdown />
    </Box>
  );
};
