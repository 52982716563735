import {
  AdministratorsIcon,
  AuthorizationIcon,
  ClinicsIcon,
  ConventionIcon,
  DashboardIcon,
  InstitutionsIcon,
  InvitesIcon,
  MemorandumIcon,
  NotificationIcon,
  PatientIcon,
  ReportsIcon,
  RequestsIcon,
} from '@lib/icons';
import { IMenuListItemConfig } from '@lib/react-components';

import { ROUTE_PATHS } from 'common/constants/routes';
import { i18n } from 'common/i18n';

// It can be changed/updated/reorganized
enum SidebarMenuItems {
  DASHBOARD = 'dashboard',
  INSTITUTIONS = 'institutions',
  USERS = 'users',
  CLINICS = 'clinics',
  REQUESTS = 'requests',
  INVITES = 'invites',
  AFFILIATIONS = 'affiliations',
  ADMINS = 'admins',
  AUTHORIZATIONS = 'authorizations',
  REPORT = 'report',
  MEMORANDUM = 'memorandum',
  ANNOUNCEMENTS = 'announcements',
  MODALS = 'modals',
}

type SidebarMenuItemsConfig = Record<SidebarMenuItems, IMenuListItemConfig>;

export const sidebarMenuItemsConfig: SidebarMenuItemsConfig = {
  [SidebarMenuItems.DASHBOARD]: {
    id: '1',
    icon: DashboardIcon,
    url: ROUTE_PATHS.dashboard,
    label: i18n.t('common.constants.sidebar.dashboard'),
  },
  [SidebarMenuItems.INSTITUTIONS]: {
    id: '2',
    icon: InstitutionsIcon,
    url: ROUTE_PATHS.institutions,
    label: i18n.t('common.constants.sidebar.institutions'),
  },
  [SidebarMenuItems.USERS]: {
    id: '3',
    icon: PatientIcon,
    url: ROUTE_PATHS.users,
    label: i18n.t('common.constants.sidebar.users'),
  },
  [SidebarMenuItems.CLINICS]: {
    id: '4',
    icon: ClinicsIcon,
    url: ROUTE_PATHS.clinics,
    label: i18n.t('common.constants.sidebar.clinics'),
  },
  [SidebarMenuItems.REQUESTS]: {
    id: '5',
    icon: RequestsIcon,
    url: ROUTE_PATHS.requests,
    label: i18n.t('common.constants.sidebar.requests'),
  },
  [SidebarMenuItems.INVITES]: {
    id: '6',
    icon: InvitesIcon,
    url: ROUTE_PATHS.invites,
    label: i18n.t('common.constants.sidebar.invites'),
  },
  [SidebarMenuItems.AFFILIATIONS]: {
    id: '7',
    icon: ConventionIcon,
    url: ROUTE_PATHS.affiliations,
    label: i18n.t('common.constants.sidebar.affiliations'),
  },
  [SidebarMenuItems.ADMINS]: {
    id: '8',
    icon: AdministratorsIcon,
    url: ROUTE_PATHS.admins,
    label: i18n.t('common.constants.sidebar.administrators'),
  },
  [SidebarMenuItems.AUTHORIZATIONS]: {
    id: '9',
    icon: AuthorizationIcon,
    url: ROUTE_PATHS.authorizations,
    label: i18n.t('common.constants.sidebar.authorizations'),
  },
  [SidebarMenuItems.REPORT]: {
    id: '10',
    icon: ReportsIcon,
    url: ROUTE_PATHS.report,
    label: i18n.t('common.constants.sidebar.report'),
  },
  [SidebarMenuItems.MEMORANDUM]: {
    id: '11',
    icon: MemorandumIcon,
    url: ROUTE_PATHS.memorandum,
    label: i18n.t('common.constants.sidebar.memorandum'),
  },
  [SidebarMenuItems.ANNOUNCEMENTS]: {
    id: '12',
    icon: NotificationIcon,
    url: ROUTE_PATHS.announcements,
    label: i18n.t('common.constants.sidebar.announcements'),
  },
  [SidebarMenuItems.MODALS]: {
    id: '13',
    icon: NotificationIcon,
    url: ROUTE_PATHS.modals,
    label: i18n.t('common.constants.sidebar.modals'),
  },
};

export const baseSidebarMenuItems: IMenuListItemConfig[] = [
  sidebarMenuItemsConfig.dashboard,
  sidebarMenuItemsConfig.institutions,
  sidebarMenuItemsConfig.users,
  sidebarMenuItemsConfig.invites,
  sidebarMenuItemsConfig.admins,
  sidebarMenuItemsConfig.report,
];

export const paidServicesSidebarMenuItems: IMenuListItemConfig[] = [sidebarMenuItemsConfig.requests];
