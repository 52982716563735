import { PersistedDataKeys, PortalContext } from '@lib/core';
import { ChevronDownIcon, MaterialSymbolsIcon } from '@lib/icons';
import { getUserFullName, IdStrings } from '@lib/utils';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { MouseEventHandler, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';

import { Maybe } from '__generated__/types';
import { client } from 'common/graphql/apollo';
import { persistedData } from 'common/helpers/persistedData';
import { i18n } from 'common/i18n';
import { useMngGetAuthenticatedAdminUser } from 'features/Auth/graphql/__generated__/MngGetAuthenticatedAdminUser.query';
import { useMngLogoutAdminUser } from 'features/Auth/graphql/__generated__/MngLogoutAdminUser.mutation';

export const UserInfoDropdown = (): Maybe<JSX.Element> => {
  const history = useHistory();

  const { classes: localClasses } = useStyles();
  const { setPortal } = useContext(PortalContext);
  const [logoutAdminUser] = useMngLogoutAdminUser(); // TODO: get 'loading' add loader component
  const { data } = useMngGetAuthenticatedAdminUser({
    fetchPolicy: 'cache-first',
  });
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const open = Boolean(anchorEl);

  const handleClick: MouseEventHandler = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout: MouseEventHandler = async (): Promise<void> => {
    const refreshToken = persistedData.get(PersistedDataKeys.RefreshToken);

    if (refreshToken) {
      try {
        const { data: logoutAdminUserData, errors } = await logoutAdminUser({
          variables: {
            refreshToken,
          },
        });

        if (errors) {
          // TODO: Implement notifications
        }

        if (logoutAdminUserData?.mngLogoutAdminUser) {
          persistedData.clear();
          setPortal(null);
          client.clearStore();
          history.push('/login');
        }
      } catch (error) {
        // TODO: Implement notifications
      }
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Box>
      <Button
        className={localClasses.dropDownWrapper}
        id={IdStrings.basicButton}
        disableTouchRipple
        aria-controls={open ? IdStrings.basicMenu : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="text"
      >
        <Typography variant="body2">{getUserFullName(data.mngGetAuthenticatedAdminUser)}</Typography>
        <MaterialSymbolsIcon
          sx={{ ...(open && { transform: 'rotate(180deg)' }) }}
          icon={<ChevronDownIcon fontWeight="300" />}
          fontSize={24}
        />
      </Button>
      <Menu
        id={IdStrings.basicMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: localClasses.itemsListWrapper, list: localClasses.itemsList }}
        MenuListProps={{
          'aria-labelledby': IdStrings.basicButton,
        }}
      >
        <MenuItem onClick={handleClose} classes={{ root: localClasses.listItem }}>
          {i18n.t('common.components.userDropdown.settings')}
        </MenuItem>
        <MenuItem onClick={handleLogout} classes={{ root: localClasses.listItem }}>
          {i18n.t('common.components.userDropdown.logout')}
        </MenuItem>
      </Menu>
    </Box>
  );
};
