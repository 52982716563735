import { leftNavigationCollapsedWidth, leftNavigationWidth, TOP_MARGIN, zIndexValues } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  sidebarCollapsed: {
    margin: `${TOP_MARGIN}px 0 30px ${leftNavigationCollapsedWidth}px`,
  },

  sidebarExpanded: {
    margin: `${TOP_MARGIN}px 0 25px ${leftNavigationWidth}px`,
  },

  sideBarTransition: {
    transition: 'margin 0.2s',
  },

  headerWrapper: {
    position: 'fixed',
    top: 0,
    left: 'auto',
    right: 0,
    zIndex: zIndexValues.header,
  },

  headerWrapperExpanded: {
    width: `calc(100% - ${leftNavigationWidth}px)`,
  },

  headerWrapperCollapsed: {
    width: `calc(100% - ${leftNavigationCollapsedWidth}px)`,
  },
}));
