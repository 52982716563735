import { PersistedDataKeys, PortalContext, PortalContextModel } from '@lib/core';
import { Select, SelectItem } from '@lib/react-components';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';

import { ROUTE_PATHS } from 'common/constants/routes';
import { persistedData } from 'common/helpers/persistedData';
import { i18n } from 'common/i18n';
import { useMngGetAuthenticatedAdminUser } from 'features/Auth/graphql/__generated__/MngGetAuthenticatedAdminUser.query';

export const PortalSelect = (): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { portal, setPortal } = useContext(PortalContext);
  const history = useHistory();
  const { data } = useMngGetAuthenticatedAdminUser({
    fetchPolicy: 'cache-first',
  });

  const adminUser = data?.mngGetAuthenticatedAdminUser;
  const allowedPortalsMap: Record<string, PortalContextModel> = (adminUser?.allowedPortals || []).reduce(
    (acc, allowedPortal) => {
      acc[allowedPortal.id] = allowedPortal;

      return acc;
    },
    {} as Record<string, PortalContextModel>
  );
  const allowedPortals = adminUser?.allowedPortals || [];
  const allowedPortalsSelectItems: SelectItem[] = allowedPortals
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleChange = ({ target: { value } }: SelectChangeEvent<string>): void => {
    setPortal(allowedPortalsMap[value]);
    persistedData.set(PersistedDataKeys.PortalSlug, allowedPortalsMap?.[value]?.slug);
    history.push(ROUTE_PATHS.dashboard);
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography className={localClasses.label}>{i18n.t('features.portals.choosePortal')}</Typography>
      <Select
        classes={{
          root: localClasses.root,
          menuItemRoot: localClasses.menuItemRoot,
          renderValue: localClasses.renderValue,
        }}
        value={portal?.id || ''}
        items={allowedPortalsSelectItems}
        onChange={handleChange}
        withoutBorder
      />
    </Box>
  );
};
