import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type MngLogoutAdminUserVariables = Types.Exact<{
  refreshToken: Types.Scalars['String']['input'];
}>;


export type MngLogoutAdminUser = { __typename?: 'Mutation', mngLogoutAdminUser: boolean };


export const MngLogoutAdminUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MngLogoutAdminUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mngLogoutAdminUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"refreshToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}}}]}]}}]} as unknown as DocumentNode;
export type MngLogoutAdminUserMutationFn = Apollo.MutationFunction<MngLogoutAdminUser, MngLogoutAdminUserVariables>;

/**
 * __useMngLogoutAdminUser__
 *
 * To run a mutation, you first call `useMngLogoutAdminUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMngLogoutAdminUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mngLogoutAdminUser, { data, loading, error }] = useMngLogoutAdminUser({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useMngLogoutAdminUser(baseOptions?: ApolloReactHooks.MutationHookOptions<MngLogoutAdminUser, MngLogoutAdminUserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MngLogoutAdminUser, MngLogoutAdminUserVariables>(MngLogoutAdminUserDocument, options);
      }
export type MngLogoutAdminUserHookResult = ReturnType<typeof useMngLogoutAdminUser>;
export type MngLogoutAdminUserMutationResult = Apollo.MutationResult<MngLogoutAdminUser>;
export type MngLogoutAdminUserMutationOptions = Apollo.BaseMutationOptions<MngLogoutAdminUser, MngLogoutAdminUserVariables>;