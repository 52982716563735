import { Loader, useInitYup } from '@lib/react-components';
import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthorizedContent } from '../AuthorizedContent';

const Login = lazy(() => import('pages/Login'));

export const App = (): JSX.Element => {
  useInitYup();

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/" component={AuthorizedContent} />
      </Switch>
    </Suspense>
  );
};
