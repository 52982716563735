import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type MngGetAuthenticatedAdminUserVariables = Types.Exact<{ [key: string]: never; }>;


export type MngGetAuthenticatedAdminUser = { __typename?: 'Query', mngGetAuthenticatedAdminUser: { __typename?: 'AdminUserModel', id: string, email: string, firstName: string, lastName: string, createdAt: string, profileType: Types.AdminProfileType, allowedPortals: Array<{ __typename?: 'PortalModel', id: string, slug: string, name: string, palette: { __typename?: 'PortalPaletteModel', disabled: string, hover: string, main: string, mainBackground: string, selected: string, softBackground: string } }> } };


export const MngGetAuthenticatedAdminUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MngGetAuthenticatedAdminUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mngGetAuthenticatedAdminUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"allowedPortals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"palette"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"disabled"}},{"kind":"Field","name":{"kind":"Name","value":"hover"}},{"kind":"Field","name":{"kind":"Name","value":"main"}},{"kind":"Field","name":{"kind":"Name","value":"mainBackground"}},{"kind":"Field","name":{"kind":"Name","value":"selected"}},{"kind":"Field","name":{"kind":"Name","value":"softBackground"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"profileType"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useMngGetAuthenticatedAdminUser__
 *
 * To run a query within a React component, call `useMngGetAuthenticatedAdminUser` and pass it any options that fit your needs.
 * When your component renders, `useMngGetAuthenticatedAdminUser` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMngGetAuthenticatedAdminUser({
 *   variables: {
 *   },
 * });
 */
export function useMngGetAuthenticatedAdminUser(baseOptions?: ApolloReactHooks.QueryHookOptions<MngGetAuthenticatedAdminUser, MngGetAuthenticatedAdminUserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MngGetAuthenticatedAdminUser, MngGetAuthenticatedAdminUserVariables>(MngGetAuthenticatedAdminUserDocument, options);
      }
export function useMngGetAuthenticatedAdminUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MngGetAuthenticatedAdminUser, MngGetAuthenticatedAdminUserVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MngGetAuthenticatedAdminUser, MngGetAuthenticatedAdminUserVariables>(MngGetAuthenticatedAdminUserDocument, options);
        }
export type MngGetAuthenticatedAdminUserHookResult = ReturnType<typeof useMngGetAuthenticatedAdminUser>;
export type MngGetAuthenticatedAdminUserLazyQueryHookResult = ReturnType<typeof useMngGetAuthenticatedAdminUserLazyQuery>;
export type MngGetAuthenticatedAdminUserQueryResult = Apollo.QueryResult<MngGetAuthenticatedAdminUser, MngGetAuthenticatedAdminUserVariables>;