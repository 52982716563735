import { PortalContext } from '@lib/core';
import { PortalLogoType, Portals, PortalsSponsors, usePortalSettings } from '@lib/features-bll';
import { PortalLogo } from '@lib/features-ui';
import {
  DrawerContent,
  leftNavigationCollapsedWidth,
  leftNavigationWidth,
  Loader,
  MenuList,
  SideNavigation,
} from '@lib/react-components';
import { useCallback, useContext } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { useGetSidebarMenuItems } from './hooks';
import { useStyles } from './styles';
import { ISidebarMenu } from './types';

import { sidebarMenuSmallTopLogoPortals } from 'common/constants/sidebarMenu';
import { useCheckIfPortalAllowed } from 'features/Portals';

export const SidebarMenu = ({ isMenuCollapsed, toggleCollapseMenuHandler }: ISidebarMenu): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { portal } = useContext(PortalContext);
  const { data: portalSettingsData } = usePortalSettings({
    variables: {
      slug: portal?.slug || '',
    },
  });

  const isPortalHasSmallTopLogo = useCheckIfPortalAllowed(sidebarMenuSmallTopLogoPortals);
  const menuItems = useGetSidebarMenuItems((portalSettingsData?.portalSettings?.slug || '') as Portals);

  const logoType = isMenuCollapsed || isPortalHasSmallTopLogo ? PortalLogoType.SMALL : PortalLogoType.NORMAL;

  const goToLink = useCallback(
    (link?: string) => (): void => {
      if (link) {
        history.push(link);
      }
    },
    [history]
  );

  const handleIsSelected = (url?: string): boolean => !!(url && matchPath(url, location.pathname));

  return (
    <SideNavigation
      desktopConfig={{
        isFullScreen: false,
        isMenuCollapsed,
        toggleCollapseMenuHandler,
      }}
      leftMenuNavigationWidths={{
        leftNavigationWidth,
        leftNavigationCollapsedWidth,
      }}
    >
      <DrawerContent
        logoTop={
          <PortalLogo
            variant={logoType}
            logoUrl={portalSettingsData?.portalSettings?.logoUrl[logoType]}
            classes={{
              icon: localClasses.svgNavLogoIconTop,
            }}
          />
        }
        logoBottom={
          portalSettingsData?.portalSettings?.sidebarBottomLogoName && (
            <PortalLogo
              variant={logoType}
              logoBottomName={portalSettingsData.portalSettings.sidebarBottomLogoName as PortalsSponsors}
              classes={{
                icon: localClasses.svgNavLogoIconBottom,
              }}
            />
          )
        }
      >
        {menuItems ? (
          <MenuList
            menuItems={menuItems}
            isMenuCollapsed={isMenuCollapsed}
            onItemClickHandler={goToLink}
            handleIsSelected={handleIsSelected}
          />
        ) : (
          <Loader />
        )}
      </DrawerContent>
    </SideNavigation>
  );
};
