import en from './en.json';
import it from './it.json';

export const locales = {
  en: {
    translation: en,
  },
  it: {
    translation: it,
  },
};
