import { fontWeight } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

const baseFont = {
  // TODO: h info
  fontSize: 14,
  fontWeight: fontWeight.regular,
  lineHeight: '20px',
};

export const useStyles = makeStyles()(theme => ({
  label: {
    ...baseFont,
    marginRight: 8,
    whiteSpace: 'nowrap',
  },

  root: {
    ...baseFont,
    width: 230,
    height: 40,
    borderRadius: 8,
  },

  menuItemRoot: {
    ...baseFont,
    '&.MuiMenuItem-root.Mui-selected': {
      backgroundColor: theme.palette.background.default,
    },
  },

  renderValue: {
    ...baseFont,
  },
}));
