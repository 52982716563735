import { PersistedDataKeys, PortalContext } from '@lib/core';
import { Portals } from '@lib/features-bll';
import { Loader } from '@lib/react-components';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { keyBy } from 'lodash-es';
import { lazy, useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { useStyles } from './styles';

import { Header } from 'common/components/Header';
import { SidebarMenu } from 'common/components/SidebarMenu';
import { ROUTE_PATHS } from 'common/constants/routes';
import { client } from 'common/graphql/apollo';
import { persistedData } from 'common/helpers/persistedData';
import { useMngGetAuthenticatedAdminUser } from 'features/Auth/graphql/__generated__/MngGetAuthenticatedAdminUser.query';

const AdminCreate = lazy(() => import('pages/AdminCreate'));
const AdminPreview = lazy(() => import('pages/AdminPreview'));
const AnnouncementCreate = lazy(() => import('pages/AnnouncementCreate'));
const AnnouncementUpdate = lazy(() => import('pages/AnnouncementUpdate'));
const Announcements = lazy(() => import('pages/Announcements'));
const Admins = lazy(() => import('pages/Admins'));
const AffiliationCreate = lazy(() => import('pages/AffiliationCreate'));
const AffiliationDetails = lazy(() => import('pages/AffiliationDetails'));
const Affiliations = lazy(() => import('pages/Affiliations'));
const AuthorizationDetails = lazy(() => import('pages/AuthorizationDetails'));
const Authorizations = lazy(() => import('pages/Authorizations'));
const ClinicCreate = lazy(() => import('pages/ClinicCreate'));
const ClinicDetails = lazy(() => import('pages/ClinicDetails'));
const ClinicEdit = lazy(() => import('pages/ClinicEdit'));
const Clinics = lazy(() => import('pages/Clinics'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const DeleteUser = lazy(() => import('pages/DeleteUser'));
const DoctorImport = lazy(() => import('pages/DoctorImport'));
const PatientImport = lazy(() => import('pages/PatientImport'));
const InstitutionCreate = lazy(() => import('pages/InstitutionCreate'));
const InstitutionEdit = lazy(() => import('pages/InstitutionEdit'));
const Institutions = lazy(() => import('pages/Institutions'));
const InviteCreate = lazy(() => import('pages/InviteCreate'));
const InviteDetails = lazy(() => import('pages/InviteDetails'));
const Invites = lazy(() => import('pages/Invites'));
const Memorandum = lazy(() => import('pages/Memorandum'));
const Modals = lazy(() => import('pages/Modals'));
const ModalsCreate = lazy(() => import('pages/ModalsCreate'));
const Report = lazy(() => import('pages/Report'));
const RequestDetails = lazy(() => import('pages/RequestDetails'));
const Requests = lazy(() => import('pages/Requests'));
const UserDetails = lazy(() => import('pages/UserDetails'));
const Users = lazy(() => import('pages/Users'));

export const AuthorizedContent = (): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { setPortal, portal } = useContext(PortalContext);
  const { data, loading, error } = useMngGetAuthenticatedAdminUser({
    fetchPolicy: 'cache-first',
  });
  const [isMenuCollapsed, setMenuCollapsed] = useState<boolean>(false);

  const allowedPortalsBySlug = keyBy(data?.mngGetAuthenticatedAdminUser.allowedPortals, 'slug');
  const lsPortalSlug = persistedData.get(PersistedDataKeys.PortalSlug);
  const { search } = useLocation();

  const welcomedicinePortal = data?.mngGetAuthenticatedAdminUser.allowedPortals.find(
    el => el.slug === Portals.WELCOMEDICINE
  );

  const defaultPortal =
    (lsPortalSlug && allowedPortalsBySlug[lsPortalSlug]) ||
    (welcomedicinePortal && allowedPortalsBySlug[welcomedicinePortal.slug]) ||
    data?.mngGetAuthenticatedAdminUser.allowedPortals[0];
  useEffect((): void => {
    if (!portal && defaultPortal) {
      setPortal(defaultPortal);
    }
  }, [defaultPortal, setPortal, portal]);

  if (error) {
    persistedData.clear();
    setPortal(null);
    client.clearStore();

    return <Redirect push to={`/login${search}`} />;
  }

  return (
    <Loader loading={loading || !portal} fullScreen>
      <Box displayPrint="none">
        <SidebarMenu
          isMenuCollapsed={isMenuCollapsed}
          toggleCollapseMenuHandler={(): void => setMenuCollapsed(!isMenuCollapsed)}
        />
        <Box
          className={clsx(
            localClasses.headerWrapper,
            isMenuCollapsed ? localClasses.headerWrapperCollapsed : localClasses.headerWrapperExpanded
          )}
        >
          <Header />
        </Box>
      </Box>
      <Box
        className={clsx(
          localClasses.sideBarTransition,
          isMenuCollapsed ? localClasses.sidebarCollapsed : localClasses.sidebarExpanded
        )}
      >
        <Switch>
          <Redirect from="/" to={ROUTE_PATHS.dashboard} exact />
          <Route path={ROUTE_PATHS.dashboard} exact component={Dashboard} />
          <Route path={ROUTE_PATHS.institutions} exact component={Institutions} />
          <Route path={ROUTE_PATHS.institutionCreate} component={InstitutionCreate} />
          <Route path={ROUTE_PATHS.institutionEdit} component={InstitutionEdit} />
          <Route path={ROUTE_PATHS.users} exact component={Users} />
          <Route path={ROUTE_PATHS.clinics} exact component={Clinics} />
          <Route path={ROUTE_PATHS.clinicCreate} component={ClinicCreate} />
          <Route path={ROUTE_PATHS.clinic} exact component={ClinicDetails} />
          <Route path={ROUTE_PATHS.clinicEdit} component={ClinicEdit} />
          <Route path={ROUTE_PATHS.admins} exact component={Admins} />
          <Route path={ROUTE_PATHS.adminCreate} component={AdminCreate} />
          <Route path={ROUTE_PATHS.adminPreview} component={AdminPreview} />
          <Route path={ROUTE_PATHS.affiliations} exact component={Affiliations} />
          <Route path={ROUTE_PATHS.affiliationCreate} component={AffiliationCreate} />
          <Route path={ROUTE_PATHS.affiliationDetails} component={AffiliationDetails} />
          <Route path={ROUTE_PATHS.userDetails} exact component={UserDetails} />
          <Route path={ROUTE_PATHS.deleteUser} exact component={DeleteUser} />
          <Route path={ROUTE_PATHS.doctorImport} exact component={DoctorImport} />
          <Route path={ROUTE_PATHS.patientImport} exact component={PatientImport} />
          <Route path={ROUTE_PATHS.authorizations} exact component={Authorizations} />
          <Route path={ROUTE_PATHS.authorizationDetails} component={AuthorizationDetails} />
          <Route path={ROUTE_PATHS.invites} exact component={Invites} />
          <Route path={ROUTE_PATHS.inviteCreate} component={InviteCreate} />
          <Route path={ROUTE_PATHS.inviteDetails} component={InviteDetails} />
          <Route path={ROUTE_PATHS.requests} exact component={Requests} />
          <Route path={ROUTE_PATHS.requestDetails} component={RequestDetails} />
          <Route path={ROUTE_PATHS.report} component={Report} />
          <Route path={ROUTE_PATHS.memorandum} component={Memorandum} />
          <Route path={ROUTE_PATHS.modals} exact component={Modals} />
          <Route path={ROUTE_PATHS.modalsCreate} exact component={ModalsCreate} />
          <Route path={ROUTE_PATHS.announcements} exact component={Announcements} />
          <Route path={ROUTE_PATHS.announcementCreate} component={AnnouncementCreate} />
          <Route path={ROUTE_PATHS.announcementUpdate} component={AnnouncementUpdate} />
        </Switch>
      </Box>
    </Loader>
  );
};
