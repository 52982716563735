import { ApolloProvider } from '@apollo/client';
import { NotificationsProvider, PortalProvider, setupSentry } from '@lib/core';
import { ReactIconEnvironmentProvider, ScrollbarGlobalStyles } from '@lib/react-components';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from 'common/components/App';
import { NotificationsConsumer } from 'common/consumers';
import { client } from 'common/graphql/apollo';
import { PortalThemeProvider } from 'features/Portals';

import 'common/i18n';

setupSentry({
  application: 'manager',
  release: import.meta.env.VITE_SENTRY_RELEASE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
});

const rootDomNode = document.getElementById('root');
if (!rootDomNode) throw new Error('Failed to find the root element');
const root = createRoot(rootDomNode);

root.render(
  <ReactIconEnvironmentProvider>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <PortalProvider>
            <PortalThemeProvider>
              <NotificationsProvider>
                <NotificationsConsumer />
                <CssBaseline />
                <ScrollbarGlobalStyles />
                <App />
              </NotificationsProvider>
            </PortalThemeProvider>
          </PortalProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </ApolloProvider>
  </ReactIconEnvironmentProvider>
);
