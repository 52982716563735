import { fontWeight } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  dropDownWrapper: {
    color: theme.palette.black.main,
    backgroundColor: theme.palette.blue.light,
    padding: 0,
    '&.MuiButton-root:hover': {
      backgroundColor: theme.palette.blue.light,
    },
    '& > svg': {
      marginLeft: 12,
    },
  },

  itemsListWrapper: {
    marginTop: 15,
  },

  itemsList: {
    padding: '12px 0',
  },

  listItem: {
    padding: '4px 0 4 24px',
    marginBottom: 8,
    minWidth: 162,
    // TODO: h body2
    fontSize: 14,
    fontWeight: fontWeight.regular,
    color: theme.palette.generic.dark,
    '&:last-child': {
      marginBottom: 0,
    },
    '&:hover': {
      color: theme.palette.generic.dark,
    },
  },
}));
